<template lang="pug">
BasePane
  Accordion(fix)
    template(#title)
      span {{ $t('action') }}
    Dropdown#after-click(
      layout="col"
      :label="$t('onClick')"
      v-model="onClick"
      :items="onClickOptions"
    )
      template(v-if="isTypeCopyCoupon" #help) {{ $t('couponTooltip') }}
        PaneLayout#copy-coupon-success.spacing(gap="4px")
          template(#left) {{ $t('couponCopy.title') }}
          template(#right)
            OmInput#redirect-url(small v-model="couponSuccess")
        PaneLayout#copy-coupon-fail.spacing(
          v-if="isActiveShopifyDomain(campaign.domain)"
          gap="4px"
        )
          template(#left) {{ $t('couponCopy.missing.title') }}
          template(#right)
            OmInput#redirect-url(small v-model="couponFailed")
          template(#help) {{ $t('couponCopy.missing.tooltip') }}
    Dropdown#chosen-page(
      v-if="onClick === 'jumpToPage'"
      :label="$t('choosedPage')"
      :items="choosablePages"
      v-model="chosenPage"
      layout="col"
    )
    template(v-if="isTypeRedirect")
      PaneLayout(gap="4px")
        template(#left) URL
        template(#right)
          OmInput#redirect-url(
            small
            v-model="redirectUrl"
            :error="!!redirectURLError"
            placeholder="https://www.example.com/"
          )
        template(v-if="redirectURLError" #error) {{ redirectURLError }}
      Heading(label) {{ $t('keepQueryParams') }}
        template(#prependEnding)
          OmSwitch#keep-query-params(:value="keepQueryParams" @switch="keepQueryParams = $event")
        template(#help)
          span {{ $t('keepQueryParamsTooltip') }}
      Heading(label) {{ $t('openInNewTab') }}
        template(#prependEnding)
          OmSwitch#open-new-tab(:value="newTab" @switch="newTab = $event")
    PhoneInput#phone-input(
      v-if="onClick === 'dial'"
      :label="$t('phoneNumber')"
      :error="!!phoneNumberError"
      :value="phoneNumber"
      @prefix="setPhonePrefix"
      @input="setNumber"
      @raw="setRawNumber"
    )
      template(v-if="phoneNumberError" #error) {{ phoneNumberError }}
    ReportAs#report-as(v-if="!isTypeBasedOnFeedback && !isTypeCopyCoupon" v-model="reportAs")
    template(v-if="!isTypeBasedOnFeedback && !isTypeCopyCoupon" #moreOrLess)
      CampaignGoalReach(v-model="campaignGoalReached")
  hr(:class="{ 'mt-1 mb-5': !isTypeCopyCoupon && !isTypeBasedOnFeedback }")
  template(v-if="mobilePreview")
    Accordion(fix)
      template(#title)
        .font-weight-bold.mb-3 {{ $t('textSettings') }}
      RangeInput(
        :label="$t('fontSize')"
        :min="0"
        :max="200"
        :step="1"
        unit="%"
        v-model.number="mobileFontSize"
      )
      AlignDropdown(v-model="mobileTextAlign" layout="col" editMobile)
    hr.mt-1.mb-5
  BackgroundAndBorder(shadow typeOfComponent="button" :deviceSelector="false")
    template(v-if="!mobilePreview" #afterBackground)
      OmColorInput(
        :label="$t('backgroundHover')"
        property="mobile.hover.color"
        typeOfComponent="button"
        layout="col"
        dsAllowed
      )
    template(v-if="!mobilePreview" #borderAfterColor)
      OmColorInput(
        :label="$t('borderHover')"
        property="hover.borderColor"
        typeOfComponent="button"
        layout="col"
        dsAllowed
      )
  hr.mt-1.mb-5
  Accordion(fix)
    template(#title)
      span {{ $t('sizeAndSpacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Dropdown#width.mt-3(
      layout="col"
      :label="$t('width')"
      :items="widthOptions"
      v-model="widthType"
    )
    RangeInput(v-if="widthType === 'manual'" label="" v-model.number="width" :max="600")
    Align(
      v-if="['manual', 'fluid'].includes(widthType) || (mobilePreview && widthType !== '100w')"
      :labelOverride="$t('align')"
      v-model="align"
    )
    RangeInput(
      v-if="!mobilePreview || widthType"
      v-model.number="height"
      :label="$t('height')"
      :max="300"
    )
    Margin
  hr.mt-3.mb-3
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
    Dropdown#sync-to-integration(
      v-if="!isTypeBasedOnFeedback && !isTypeCopyCoupon"
      layout="col"
      :label="$t('syncToIntegration.label')"
      :items="syncToIntegrationOptions"
      v-model="syncToIntegration"
    )
      template(v-if="syncToIntegrationTooltip" #help) {{ syncToIntegrationTooltip }}
</template>

<script>
  import { get } from 'lodash-es';
  import { mapGetters, mapState } from 'vuex';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import ReportAs from '@/components/Editor/Controls/ReportAs.vue';
  import PhoneInput from '@/components/Editor/PhoneInput/PhoneInput.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import CampaignGoalReach from '@/components/Editor/Controls/CampaignGoalReach.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import AlignDropdown from '@/components/Editor/Controls/Alignments/AlignDropdown.vue';
  import itemMixin from '@/editor/mixins/item';
  import buttonMixin from '@/editor/mixins/button';
  import pageValidationMixin from '@/editor/mixins/pageValidation';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      Dropdown,
      ReportAs,
      Heading,
      DeviceSelector,
      PhoneInput,
      PaneLayout,
      RangeInput,
      Align,
      CampaignGoalReach,
      Margin,
      Hide,
      AlignDropdown,
      BackgroundAndBorder,
    },
    mixins: [itemMixin, buttonMixin, pageValidationMixin],
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      ...mapState(['selectedPage', 'campaign', 'validationError', 'mobilePreview']),
      ...mapGetters(['isActiveShopifyDomain', 'isNano']),
      onClickOptions() {
        return this.actionTypes.map(({ key, value }) => ({ text: this.$t(value), value: key }));
      },
      onClick: {
        get() {
          return this.getValueOf('data.action');
        },
        set(value) {
          this.setValueOf('data.action', value);
        },
      },
      couponSuccess: {
        get() {
          return this.getValueOf('data.coupon.successCopyText');
        },
        set(v) {
          this.setValueOf('data.coupon.successCopyText', v);
        },
      },
      couponFailed: {
        get() {
          return this.getValueOf('data.coupon.failureCopyText');
        },
        set(value) {
          this.setValueOf('data.coupon.failureCopyText', value);
        },
      },
      reportAs: {
        get() {
          return this.getValueOf('data.reportAs');
        },
        set(value) {
          this.setValueOf('data.reportAs', value);
        },
      },
      campaignGoalReached: {
        get() {
          return this.getValueOf('data.status');
        },
        set(v) {
          this.setValueOf('data.status', v);
        },
      },
      syncToIntegrationOptions() {
        return [
          { value: 'smartSend', text: this.$t('syncToIntegration.smartSend.label') },
          { value: 'rightNow', text: this.$t('syncToIntegration.rightNow.label') },
        ];
      },
      syncToIntegration: {
        get() {
          return this.getValueOf('data.syncToIntegration');
        },
        set(value) {
          this.setValueOf('data.syncToIntegration', value);
        },
      },
      syncToIntegrationTooltip() {
        const key = this.getValueOf('data.syncToIntegration');
        return this.$t(`syncToIntegration.${key}.tooltip`);
      },
      choosablePages() {
        const pages = this.frameStore().getters.pages || [];
        const choosablePages = pages
          .map((page, index) => {
            if (page.uid === this.selectedPage.uid) return;

            const text = get(page, 'data.title') || index + 1;
            return { text, value: index };
          })
          .filter(Boolean);

        choosablePages.unshift({ text: this.$t('none'), value: 'none' });

        return choosablePages;
      },
      chosenPage: {
        get() {
          return this.getValueOf('data.jumpToPage', 'none');
        },
        set(value) {
          this.setValueOf('data.jumpToPage', value);
        },
      },
      redirectUrl: {
        get() {
          return this.getValueOf('data.redirectUrl');
        },
        set(v) {
          this.setValueOf('data.redirectUrl', v);
        },
      },
      keepQueryParams: {
        get() {
          return this.getValueOf('data.keepQueryParams');
        },
        set(v) {
          this.setValueOf('data.keepQueryParams', v);
        },
      },
      newTab: {
        get() {
          return this.getValueOf('data.newTab');
        },
        set(v) {
          this.setValueOf('data.newTab', v);
        },
      },
      isTypeRedirect() {
        return this.onClick === 'redirect';
      },
      isTypeCopyCoupon() {
        return this.onClick === 'copyCoupon';
      },
      isTypeBasedOnFeedback() {
        return this.onClick === 'basedOnFeedback';
      },
      phoneNumber() {
        return this.getValueOf('data.phoneNumber');
      },
      phoneNumberError() {
        return this.getValidationError('data.rawPhoneNumber');
      },
      redirectURLError() {
        return this.getValidationError('data.redirectUrl');
      },
      shadow: {
        get() {
          return this.getValueOf('shadow.type');
        },
        set(value) {
          this.setValueOf('shadow.type', value);
        },
      },
      widthOptions() {
        const opts = [
          { value: 'fluid', text: this.$t('fluid') },
          { value: 'manual', text: this.$t('manual') },
        ];

        if (!this.isNano) {
          opts.push({ value: '100w', text: this.$t('simple100') });
        }

        if (this.mobilePreview) {
          opts.unshift({ value: null, text: this.$t('default') });
        }

        return opts;
      },
      widthType: {
        get() {
          return this.getValueOf('$device.smartSize.type');
        },
        set(value) {
          this.setValueOf('$device.smartSize.type', value);
        },
      },
      width: {
        get() {
          const mobileValue = this.getValueOf('mobile.smartSize.width');
          if (mobileValue === null) {
            return Number(this.smartGetValueOf('desktop.smartSize.width'));
          }
          return Number(this.smartGetValueOf('$device.smartSize.width'));
        },
        set(v) {
          this.setValueOf('$device.smartSize.width', v);
        },
      },
      height: {
        get() {
          const mobileValue = this.getValueOf('mobile.height');
          if (mobileValue === null) {
            return Number(this.getValueOf('desktop.height'));
          }
          return Number(this.getValueOf('$device.height'));
        },
        set(v) {
          this.setValueOf('$device.height', v);
        },
      },
      align: {
        get() {
          return this.getValueOf('$device.justifyContent');
        },
        set(value) {
          this.setValueOf('$device.justifyContent', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      mobileFontSize: {
        get() {
          return Number(this.getValueOf('mobile.fontMultiplier'));
        },
        set(value) {
          this.setValueOf('mobile.fontMultiplier', value);
        },
      },
      mobileTextAlign: {
        get() {
          return this.getValueOf('mobile.textAlign');
        },
        set(value) {
          this.setValueOf('mobile.textAlign', value);
        },
      },
    },
    watch: {
      onClick(value) {
        if (value === 'redirect') {
          this.setValueOf('data.newTab', true);
        }
        if (value === 'copyCoupon') this.handleCopyCoupon();

        this.resetCouponRedeemAction();
      },
    },
    mounted() {
      const isNextPopupClick = this.onClick === 'nextPopup';

      if (!this.isNextPageValid && isNextPopupClick) {
        const actionType = this.isEmbedded ? 'jumpToPage' : 'closePopup';
        this.setValueOf('data.action', actionType);
      }
    },
    methods: {
      frameStore() {
        const workspace = document.getElementById('workspaceFrame');
        return workspace.contentWindow.om.store;
      },
      setPhonePrefix(value) {
        this.setValueOf('data.prefix', `+${value}`);
      },
      setNumber(value) {
        this.setValueOf('data.phoneNumber', value);
      },
      setRawNumber(value) {
        this.setValueOf('data.rawPhoneNumber', value === 'null' ? null : value);
        this.setValueOf('data.phoneNumber', null);
      },
      handleCopyCoupon() {
        this.setValueOf('data.reportAs', 'none');
        this.setValueOf('data.status', false);
      },
      resetCouponRedeemAction() {
        if (this.onClick === 'couponRedeem' && !this.isActiveShopifyDomain(this.campaign.domain)) {
          this.actionType = 'closePopup';
        }
      },
      getValidationError(property) {
        return this.validationError.property === property
          ? this.$t(this.validationError.msg)
          : false;
      },
    },
  };
</script>

<style lang="sass">
  .spacing
    margin-top: .75rem
  .intl-tel-input
    .country-list
      .divider
        width: auto
        height: auto
        background: initial
</style>
