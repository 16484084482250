<template lang="pug">
.option-container
  Dropdown#on-click(
    :label="$t('onClick')"
    v-model="onClick"
    :items="surveyActionTypes"
    layout="col"
  )
  Heading(label) {{ $t('dontCountAsConversion') }}
    template(#prependEnding)
      OmSwitch#conversion-cnt(v-model="dontCountAsConversion")
  template(v-if="actionType === 'jumpToPage'")
    Heading(label) {{ $t('samePageForAllOptions') }}
      template(#prependEnding)
        OmSwitch#same-page-for-all-options(
          v-model="samePageForAllOptions"
          @switch="setJumpToAll()"
        )
    template(v-if="samePageForAllOptions")
      Dropdown#jump-to-page(
        :label="$t('jumpToPage')"
        v-model="jumpToPage"
        :items="pageNames"
        @input="setJumpToAll()"
        layout="col"
      )
    template(v-else)
      div(v-for="(item, index) in options" :key="item.value")
        Dropdown(
          :id="`jump-to-page-${index}`"
          :label="item.key"
          :value="optionValue(index, 'jumpToPage')"
          @input="setOptionValue(index, $event)"
          :items="pageNames"
          layout="col"
        )
  template(v-else-if="actionType === 'redirect'")
    Heading(label) {{ $t('sameRedirectForAllOptions') }}
      template(#prependEnding)
        OmSwitch#same-redirect-for-all-options(v-model="sameRedirectForAllOptions")
    template(v-if="sameRedirectForAllOptions")
      OmInput#redirect(
        :label="$t('redirect')"
        :value="redirectUrlForAll"
        @input="setRedirectToAll($event)"
        placeholder="https://www.example.com/"
        :errorText="$t(redirectUrlAllError)"
        :error="redirectUrlAllError.length > 1"
        small
      )
      Heading(label) {{ $t('keepQueryParams') }}
        template(#prependEnding)
          OmSwitch#keep-query-params(v-model="keepQueryParams")
      Heading(label) {{ $t('openInNewTab') }}
        template(#prependEnding)
          OmSwitch#open-in-new-tab(v-model="openInNewTab")
    template(v-else)
      .option-container(v-for="(item, index) in options" :key="item.value")
        OmInput(
          :id="`redirect-url-${index}`"
          :label="item.key.length > 20 ? `${item.key.substr(0, 20)}...` : item.key"
          :value="redirectUrlForOptionItem(index)"
          @input="updateOption(index, 'redirectUrl', $event)"
          placeholder="https://www.example.com/"
          :errorText="$t(redirectUrlOptionError(index))"
          :error="redirectUrlOptionError(index).length > 1"
          small
        )
        Heading(label) {{ $t('keepQueryParams') }}
          template(#prependEnding)
            OmSwitch(
              :id="`keep-query-params-${index}`"
              :value="keepQueryParamsOnItem(index)"
              @switch="setKeepQueryParamsOnItem(index, $event)"
            )
        Heading(label) {{ $t('openInNewTab') }}
          template(#prependEnding)
            OmSwitch(
              :id="`open-in-new-tab-${index}`"
              :value="openInNewTabOnItem(index)"
              @switch="setOpenInNewTabOnItem(index, $event)"
            )
</template>

<script>
  import itemMixin from '@/editor/mixins/item';
  import pageValidationMixin from '@/editor/mixins/pageValidation';
  import { mapState, mapGetters } from 'vuex';
  import { debounce } from 'lodash-es';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import Heading from '@/components/Editor/Heading.vue';

  export default {
    components: {
      Dropdown,
      Heading,
    },
    mixins: [itemMixin, pageValidationMixin],
    computed: {
      ...mapState(['selectedElement', 'pages', 'validationError', 'pages']),
      ...mapGetters(['selectedPageIndex', 'hasAccountFeature', 'isEmbedded']),
      onClick: {
        get() {
          return this.getValueOf('data.actionType');
        },
        set(v) {
          this.setValueOf('data.actionType', v);
          this.actionTypeChanged(v);
        },
      },
      dontCountAsConversion: {
        get() {
          return this.getValueOf('data.dontCountAsConversion');
        },
        set(v) {
          this.setValueOf('data.dontCountAsConversion', v);
        },
      },
      samePageForAllOptions: {
        get() {
          return this.getValueOf('data.samePageForAllOptions');
        },
        set(v) {
          this.setValueOf('data.samePageForAllOptions', v);
          this.setAllOptionsProperty('jumpToPage', v);
        },
      },
      jumpToPage: {
        get() {
          return this.getValueOf('data.jumpToPage');
        },
        set(v) {
          this.setValueOf('data.jumpToPage', v);
          this.setAllOptionsProperty('jumpToPage', v);
        },
      },
      sameRedirectForAllOptions: {
        get() {
          return this.getValueOf('data.sameRedirectForAllOptions');
        },
        set(v) {
          this.setValueOf('data.sameRedirectForAllOptions', v);
          this.setRedirectToAll();
        },
      },
      keepQueryParams: {
        get() {
          return this.getValueOf('data.keepQueryParams');
        },
        set(v) {
          this.setValueOf('data.keepQueryParams', v);
          this.setAllOptionsProperty('keepQueryParams', v);
        },
      },
      openInNewTab: {
        get() {
          return this.getValueOf('data.newTab');
        },
        set(v) {
          this.setValueOf('data.newTab', v);
          this.setAllOptionsProperty('newTab', v);
        },
      },
      redirectUrlForAll() {
        return this.getValueOf('data.redirectUrl');
      },
      optionValue() {
        return (index, action) =>
          this.selectedElement.data.form.customSettings.options[index][action];
      },
      surveyActionTypes() {
        const baseActionTypes = [
          ...(this.isNextPageValid ? [{ text: this.$t('customStep'), value: 'jumpToPage' }] : []),
          { text: this.$t('redirect'), value: 'redirect' },
          ...(!this.isEmbedded ? [{ text: this.$t('closePopup'), value: 'closePopup' }] : []),
        ];

        if (!this.isNextPageValid) {
          return baseActionTypes;
        }

        const nextPopupType = { text: this.$t('nextPopup'), value: 'nextPopup' };
        return [nextPopupType, ...baseActionTypes];
      },
      actionType() {
        return this.selectedElement.data.actionType;
      },
      pageNames() {
        const names = [];
        this.pages.forEach((p, index) => {
          if (index > this.selectedPageIndex) {
            names.push({ text: p.data.title, value: index + 1 });
          }
        });

        return [{ text: this.$t('close'), value: null }, ...names];
      },
      options() {
        return this.selectedElement.data.form.customSettings.options;
      },
      optionsLength() {
        return this.options.length;
      },
      redirectUrlAllError() {
        if (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('surveyRedirectUrlAll')
        ) {
          return this.validationError.msg;
        }
        return '';
      },
    },
    watch: {
      optionsLength() {
        this.actionTypeChanged(this.actionType);

        if (this.samePageForAllOptions && this.actionType === 'jumpToPage') {
          this.setAllOptionsProperty('jumpToPage', this.selectedElement.data.jumpToPage);
        }

        if (this.sameRedirectForAllOptions && this.actionType === 'redirect') {
          this.setAllOptionsProperty('keepQueryParams', this.selectedElement.data.keepQueryParams);
          this.setAllOptionsProperty('newTab', this.selectedElement.data.newTab);
          this.setAllOptionsProperty('redirectUrl', this.selectedElement.data.redirectUrl);
        }
      },
    },
    mounted() {
      const isNextPopupClick = this.onClick === 'nextPopup';
      const isJumpToPageClick = this.onClick === 'jumpToPage';

      if (!this.isNextPageValid && (isNextPopupClick || isJumpToPageClick)) {
        const actionType = this.isEmbedded ? 'redirect' : 'closePopup';
        this.setValueOf('data.actionType', actionType);
      }
    },
    methods: {
      setJumpToAll() {
        this.setAllOptionsProperty('jumpToPage', this.selectedElement.data.jumpToPage);
      },
      setOptionValue(index, key) {
        this.setValueOf(`data.form.customSettings.options.${index}.jumpToPage`, key);
        this.updateAllOptions();
      },
      redirectUrlForOptionItem(index) {
        return this.getValueOf(`data.form.customSettings.options.${index}.redirectUrl`);
      },
      keepQueryParamsOnItem(index) {
        return this.getValueOf(`data.form.customSettings.options.${index}.keepQueryParams`);
      },
      setKeepQueryParamsOnItem(index, value) {
        this.setValueOf(`data.form.customSettings.options.${index}.keepQueryParams`, value);
      },
      openInNewTabOnItem(index) {
        return this.getValueOf(`data.form.customSettings.options.${index}.newTab`);
      },
      setOpenInNewTabOnItem(index, value) {
        this.setValueOf(`data.form.customSettings.options.${index}.newTab`, value);
      },
      updateAllOptions() {
        this.$store.commit('updateData', {
          property: 'selectedElement.data.form.customSettings.options',
          value: this.selectedElement.data.form.customSettings.options,
        });
      },
      setAllOptionsProperty(property, value) {
        this.selectedElement.data.form.customSettings.options.forEach((o) => {
          o[property] = value;
        });
        this.updateAllOptions();
      },
      actionTypeChanged(value) {
        this.setAllOptionsProperty('action', value);
      },
      setRedirectToAll: debounce(function (value) {
        const globalValue = this.selectedElement.data.redirectUrl;
        this.setAllOptionsProperty('redirectUrl', value ?? globalValue);
        this.$set(this.selectedElement.data, 'redirectUrl', value ?? globalValue);
      }, 600),
      updateOption: debounce(function (index, property, value) {
        this.$set(this.selectedElement.data.form.customSettings.options[index], property, value);
      }, 600),
      redirectUrlOptionError(index) {
        if (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('surveyRedirectUrlOption') &&
          this.validationError.errors.some((e) => e.index === index)
        ) {
          return this.validationError.msg;
        }
        return '';
      },
    },
  };
</script>
<style lang="sass" scoped>
  .option-container
    display: flex
    flex-direction: column
    gap: 12px
    .ds-dropdown-label
      white-space: normal
</style>
<style lang="sass">
  .option-container
    .ds-dropdown-label
      white-space: normal
      min-width: 100%
</style>
